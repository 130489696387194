import { ApolloError } from "@apollo/client";
import { useCallback } from "react";
import { ExecutionResult } from "@apollo/react-common";
import {
  UpdateCartData,
  UpdateCartProps,
  useUpdateCart,
} from "src/hooks/useUpdateCart";
import { useSetLineItemSupplyChannel } from "src/hooks/useSetLineItemSupplyChannel";
import { LineItem } from "src/types/ctgraphql.d";

interface IncreaseCartItemHook {
  data?: UpdateCartData;
  loading: boolean;
  error?: ApolloError;
  increaseCartItem: (
    sku: string,
    cart: LineItem | undefined
  ) => Promise<ExecutionResult<UpdateCartData> | undefined>;
}

const useIncreaseCartItem = ({
  refetchQueries = [],
  onCompleted,
}: UpdateCartProps = {}): IncreaseCartItemHook => {
  const { setLineItemSupplyChannel } = useSetLineItemSupplyChannel({
    refetchQueries: ["cart"],
  });

  const { updateCart, data, loading, error } = useUpdateCart({
    refetchQueries,
    onCompleted: (updateMyCartData) => {
      const item = updateMyCartData.updateMyCart?.lineItems.find(
        ({ supplyChannel }) => !supplyChannel
      );

      if (item?.variant?.availability?.channels?.results?.[0]?.channel?.id) {
        setLineItemSupplyChannel(
          item.id,
          item.variant?.availability?.channels?.results?.[0]?.channel.id
        );
      }

      if (onCompleted) {
        onCompleted(updateMyCartData);
      }
    },
  });

  const increaseCartItem = useCallback(
    (sku: string, item: LineItem | undefined) =>
      updateCart([
        {
          addLineItem: {
            sku,
            ...(item?.supplyChannel?.id
              ? {
                  supplyChannel: {
                    id: item.supplyChannel.id,
                  },
                }
              : {}),
          },
        },
      ]),
    [updateCart]
  );

  return { increaseCartItem, data, loading, error };
};

export { useIncreaseCartItem };
