import { useState, useCallback, useContext, useEffect } from "react";
import { useProductBySlug } from "src/hooks/useProductBySlug";
import { Text } from "@otrium/atoms";
import { Box, Flex } from "@otrium/core";
import { ProductOrder } from "src/organisms/ProductOrder";
import { ProductVariation } from "src/types/graphql.d";
import { AppCtx } from "src/contexts/app.context";
import { Trans } from "@lingui/macro";
import { Button } from "src/atoms/Button";
import { CloseButton } from "src/atoms/CloseButton";
import { ScreenLoader } from "src/molecules/ScreenLoader";
import { useCart } from "src/hooks/useCart";
import { useIncreaseCartItem } from "src/hooks/useIncreaseCartItem";
import { useSegment } from "src/hooks/useSegment";
import { PromotionContextProvider } from "src/contexts/promotion.context";

const WishListProductSelector = ({
  productSlug,
  onCloseModal,
}: {
  productSlug: string;
  onCloseModal: () => void;
}): JSX.Element => {
  const [size, setSize] = useState<ProductVariation>();
  const [addToCartError, setAddToCartError] = useState<boolean>();

  const { setAddedProdToCart } = useContext(AppCtx);

  const { product, loading: productLoading } = useProductBySlug(productSlug);
  const { data } = useCart();

  const {
    segmentProductOutOfStock,
    segmentProductSizeSelected,
    segmentProductAdded,
  } = useSegment();

  const availableSizes: ProductVariation[] =
    product?.variations?.filter(
      (variation: ProductVariation) => variation.stock_quantity > 0
    ) || [];

  const selectedSize =
    size?.size ||
    (availableSizes.length === 1 ? (availableSizes[0]?.size as string) : "");

  const { increaseCartItem, loading, error } = useIncreaseCartItem({
    onCompleted: () => {
      onCloseModal();

      if (product) {
        void segmentProductAdded(product, selectedSize);
        setAddedProdToCart({
          productName: product.name,
          brandName: product.brand.name,
          price: product.price,
          regularPrice: product.regular_price,
          image: product.images?.[0] as string,
          selectedSize,
        });
      }
    },
  });

  useEffect(() => {
    if (!!addToCartError && product?.id) {
      void segmentProductOutOfStock(product, selectedSize);
    }
  }, [addToCartError, product?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      setAddToCartError(true);
    }
  }, [setAddToCartError, error]);

  useEffect(() => {
    if (product && size) {
      void segmentProductSizeSelected(product, selectedSize);
    }
  }, [size]);

  const handleAddToCart = useCallback(
    (variationId: string) => {
      const item = data.cart?.lineItems.find(
        (lineItem) => lineItem?.variant?.sku === variationId
      );
      const currentQuantity = (item?.quantity as number) || 0;
      const availableQuantity =
        item?.variant?.availability?.channels?.results.reduce(
          (sum, stockInfo) =>
            sum + (stockInfo.availability.availableQuantity as number),
          0
        );

      if (
        availableQuantity &&
        currentQuantity &&
        availableQuantity <= currentQuantity
      ) {
        setAddToCartError(true);
      } else {
        void increaseCartItem(variationId, item);
      }
    },
    [increaseCartItem, data]
  );

  if (!product && productLoading) {
    return <ScreenLoader />;
  }

  return (
    <Box
      data-testid="wishlist-modal-content-add-to-cart"
      bg="White"
      p={4}
      width={["auto", "auto", "auto", "auto", "400px"]}
    >
      {product && !addToCartError && (
        <>
          <Flex justifyContent="space-between">
            <Text
              fontSize={3}
              lineHeight="32px"
              letterSpacing="1.6px"
              fontWeight="700"
              color="tone.anthraciteDark"
            >
              <Trans>add to cart</Trans>
            </Text>
            <CloseButton onClick={onCloseModal} />
          </Flex>
          <PromotionContextProvider>
            <ProductOrder
              product={product}
              onAddToCart={handleAddToCart}
              addToCartLoading={loading}
              handleSizeChange={setSize}
              hasSizeGuide={false}
              shippingAndDeliveryInfo={false}
              hasAddToWishlistButton={false}
            />
          </PromotionContextProvider>
        </>
      )}
      {addToCartError && (
        <>
          <Flex justifyContent="space-between">
            <Text
              fontSize={3}
              lineHeight="32px"
              letterSpacing="1.6px"
              fontWeight="700"
              color="tone.anthraciteDark"
            >
              <Trans>Out of stock</Trans>
            </Text>
            <CloseButton fill="semantic.red" onClick={onCloseModal} />
          </Flex>
          <Box mb={3} pt={2}>
            <Text
              mt={2}
              fontSize={1}
              lineHeight="24px"
              letterSpacing="0.4px"
              color="tone.anthraciteDark"
            >
              <Trans>
                We’re really sorry, but it seems like this item is out of stock.
              </Trans>
            </Text>
          </Box>
          <Button onClick={onCloseModal} variant="tertiary" fullWidth>
            <Trans>Continue shopping</Trans>
          </Button>
        </>
      )}
    </Box>
  );
};

export default WishListProductSelector;
