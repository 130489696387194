import { Text } from "@otrium/atoms";
import { Flex } from "@otrium/core";
import { keyframes } from "@emotion/css";
import styled from "@emotion/styled";
import { Ruler as RulerIcon } from "src/icons/Ruler";
import BaseButton from "src/atoms/Button/BaseButton";
import { Button } from "src/atoms/Button";

const addToCartAnimation = keyframes`
    0% {    
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const StyledProductOrder = styled.div`
  margin-top: ${({ theme }) => theme.space[5]}px;

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-top: ${({ theme }) => theme.space.space16};
  }
`;

const Ruler = styled(RulerIcon)`
  height: 24px;
  width: 24px;
  fill: ${({ theme }) => theme.colors.tone.black};
`;

const SizeGuideButton = styled(BaseButton)`
  background: none;
  border: none;
  cursor: pointer;
`;

const SizeGuideLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.43;
  color: ${({ theme }) => theme.colors.tone.black};
  margin-left: ${({ theme }) => theme.space[2]}px;
  text-decoration: underline;
`;

const SizeOption = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  line-height: 20.11px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.tone.black};
`;

const MobileAddToCartFixed = styled(Flex)`
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: ${({ theme }) => theme.space[3]}px;
  background-color: ${({ theme }) => theme.colors.tone.white};
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
  opacity: 1;
  animation: ${addToCartAnimation} 200ms linear;
`;

const SelectSizeError = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.semantic.red};
`;

const StyledAddingToCartButton = styled(Button)`
  max-width: none !important;
  flex: 1 1 auto;
  width: auto;
`;

export {
  StyledProductOrder,
  SizeGuideLabel,
  Ruler,
  SizeGuideButton,
  SizeOption,
  MobileAddToCartFixed,
  SelectSizeError,
  StyledAddingToCartButton,
};
