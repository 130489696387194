import { FC, SVGAttributes } from "react";

const FastDelivery: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 28 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#000"
        strokeWidth="1.5"
        fill="#FFF"
        d="M6.75.75h12.5v8.5H6.75zM21.75 2.75v9.5h5.5V6A3.25 3.25 0 0024 2.75h-2.25z"
      />
      <path fill="#000" d="M8 11h20v2H8z" />
      <circle
        stroke="#000"
        strokeWidth="1.5"
        fill="#FFF"
        cx="13.5"
        cy="11.5"
        r="2.75"
      />
      <circle
        stroke="#000"
        strokeWidth="1.5"
        fill="#FFF"
        cx="23.5"
        cy="11.5"
        r="2.75"
      />
      <path
        fill="#000"
        d="M1.75.75h2v1h-2zM0 3.75h3.5v1H0zM1.75 6.75h2v1h-2zM0 9.75h3.5v1H0z"
      />
    </g>
  </svg>
);

export { FastDelivery };
