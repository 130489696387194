import { WishListProductSelector } from "src/organisms/WishListProductSelector";
import { Modal } from "src/atoms/Modal";
import { FC, useCallback, useEffect, useState } from "react";
import { useBreakpoints } from "@otrium/core";

interface FavouritesSizeSelectorProps {
  productSlug?: string;
  onClose: () => void;
}

const FavouritesSizeSelector: FC<FavouritesSizeSelectorProps> = ({
  productSlug,
  onClose,
}) => {
  const { isMobile } = useBreakpoints();
  const [sizeViewOpened, setSizeViewOpened] = useState(false);

  const openSizeSelector = useCallback(() => {
    setSizeViewOpened(true);
  }, []);

  const closeSizeSelector = useCallback(() => {
    setSizeViewOpened(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (productSlug) {
      openSizeSelector();
    }
  }, [openSizeSelector, productSlug]);

  return (
    <Modal
      overFlow="visible"
      isOpen={sizeViewOpened}
      onClose={closeSizeSelector}
      hasMobileBottomAlign={isMobile}
      fullWidth
      fullHeight={!isMobile}
    >
      {productSlug && (
        <WishListProductSelector
          onCloseModal={closeSizeSelector}
          productSlug={productSlug}
        />
      )}
    </Modal>
  );
};

export default FavouritesSizeSelector;
