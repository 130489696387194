import { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { ApolloError } from "@apollo/client";
import { useSession } from "next-auth/client";
import { Query } from "src/types/ctgraphql.d";
import { moneyFragment } from "src/hooks/useCart";
import { AppCtx } from "src/contexts/app.context";
import { Iso2LetterLocale } from "lib/Intl";
interface ShippingMethodsByLocationData {
  shippingMethodsByLocation: Query["shippingMethodsByLocation"];
}

interface ShippingMethodsByLocationVars {
  locale: string;
  country: string;
}

interface ShippingMethodsByLocationHook {
  data?: ShippingMethodsByLocationData;
  loading: boolean;
  error?: ApolloError;
}

const SHIPPING_METHODS = gql`
  query shippingMethodsByLocation($locale: Locale!, $country: Country!) {
    shippingMethodsByLocation(country: $country) {
      id
      key
      name
      localizedName(locale: $locale)
      localizedDescription(locale: $locale)
      isDefault
      zoneRates {
        zone {
          id
          name
          key
        }
        shippingRates {
          isMatching
          price {
            ...money
          }
          freeAbove {
            ...money
          }
        }
      }
    }
  }

  ${moneyFragment}
`;

const useShippingMethodsByLocation = ({
  country,
  onCompleted,
}: {
  country: Iso2LetterLocale;
  onCompleted?: () => void;
}): ShippingMethodsByLocationHook => {
  const { storeConfig } = useContext(AppCtx);
  const [session] = useSession();

  const { data, loading, error } = useQuery<
    ShippingMethodsByLocationData,
    ShippingMethodsByLocationVars
  >(SHIPPING_METHODS, {
    variables: {
      locale: storeConfig.locale,
      country,
    },
    context: { useCT: true },
    ssr: true,
    skip: !session,
    onCompleted,
  });

  return { data, loading, error };
};

export { useShippingMethodsByLocation };
