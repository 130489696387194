import { ApolloError } from "@apollo/client";
import { useCallback } from "react";
import {
  UpdateCartData,
  UpdateCartProps,
  useUpdateCart,
} from "src/hooks/useUpdateCart";

interface SetLineItemSupplyChannelHook {
  data?: UpdateCartData;
  loading: boolean;
  error?: ApolloError;
  setLineItemSupplyChannel: (lineItemId: string, channelId: string) => void;
}

const useSetLineItemSupplyChannel = ({
  refetchQueries = [],
  onCompleted,
}: UpdateCartProps = {}): SetLineItemSupplyChannelHook => {
  const { updateCart, data, loading, error } = useUpdateCart({
    refetchQueries,
    onCompleted,
  });

  const setLineItemSupplyChannel = useCallback(
    (lineItemId: string, channelId: string) =>
      updateCart([
        {
          setLineItemSupplyChannel: {
            lineItemId,
            supplyChannel: {
              id: channelId,
            },
          },
        },
      ]),
    [updateCart]
  );

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return { setLineItemSupplyChannel, data, loading, error };
};

export { useSetLineItemSupplyChannel };
