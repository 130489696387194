import gql from "graphql-tag";
import { useCallback, useContext, useState } from "react";
import { ApolloError } from "@apollo/client";
import { useMutation } from "@apollo/react-hooks";
import { ExecutionResult } from "@apollo/react-common";
import { cartFragment } from "src/hooks/useCart";
import { CartUpdateAction, Mutation } from "src/types/ctgraphql.d";
import { useCreateCart } from "./useCreateCart";
import { AppCtx } from "src/contexts/app.context";

export interface UpdateCartData {
  updateMyCart: Mutation["updateMyCart"];
}

export interface UpdateCartProps {
  refetchQueries?: string[];
  onCompleted?: (updateCartData: UpdateCartData) => void;
  onError?: (updateCartError: ApolloError) => void;
}

interface UpdateCartVars {
  id: string;
  version: number;
  locale: string;
  actions: CartUpdateAction[];
}

interface UpdateCartHook {
  data?: UpdateCartData;
  loading: boolean;
  error?: ApolloError;
  updateCart: (
    actions: CartUpdateAction[]
  ) => Promise<ExecutionResult<UpdateCartData> | undefined>;
}

const UPDATE_CART = gql`
  mutation updateMyCart(
    $id: String!
    $version: Long!
    $locale: Locale!
    $actions: [MyCartUpdateAction!]!
  ) {
    updateMyCart(id: $id, version: $version, actions: $actions) {
      ...cart
    }
  }

  ${cartFragment}
`;

const useUpdateCart = ({
  refetchQueries = [],
  onCompleted,
  onError,
}: UpdateCartProps): UpdateCartHook => {
  const {
    locale,
    setCartInfo,
    cartInfo,
    storeConfig,
    setCartMinLineItemsTotalPriceDiff,
  } = useContext(AppCtx);

  const [cartUpdateActions, setCartUpdateActions] = useState<
    CartUpdateAction[]
  >([]);

  const [updateCartMutation, { data, loading, error }] = useMutation<
    UpdateCartData,
    UpdateCartVars
  >(UPDATE_CART, {
    refetchQueries,
    onCompleted: (updateCartData) => {
      setCartMinLineItemsTotalPriceDiff(
        updateCartData?.updateMyCart?.lineItems
      );

      if (onCompleted) {
        onCompleted(updateCartData);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onError: async (apolloError) => {
      const versionMisMatchError = apolloError.graphQLErrors.find(
        (gqlErr) => gqlErr?.extensions?.code === "ConcurrentModification"
      );

      if (onError && !versionMisMatchError) {
        onError(apolloError);
      }

      if (versionMisMatchError) {
        setCartInfo(
          cartInfo.id,
          versionMisMatchError?.extensions?.currentVersion as number
        );
        await updateCart(cartUpdateActions);
        setCartUpdateActions([]);
      }
    },
  });

  const { createCart } = useCreateCart();

  const updateCart = useCallback(
    async (actions: CartUpdateAction[]) => {
      if (!actions.length) {
        return;
      }

      setCartUpdateActions(actions);

      let createdCartData;
      if (!cartInfo.id && !cartInfo.version) {
        createdCartData = await createCart({
          currency: storeConfig.currencyCode,
        });
      }

      const updatedCart = await updateCartMutation({
        variables: {
          id: createdCartData?.data?.createMyCart?.id || cartInfo.id,
          version:
            (createdCartData?.data?.createMyCart?.version as number) ||
            cartInfo.version,
          locale,
          actions,
        },
        context: { useCT: true },
      });

      if (updatedCart?.data?.updateMyCart) {
        setCartInfo(
          updatedCart.data?.updateMyCart?.id,
          updatedCart.data?.updateMyCart?.version as number
        );
      }

      return updatedCart;
    },
    [
      updateCartMutation,
      cartInfo,
      createCart,
      locale,
      setCartInfo,
      storeConfig.currencyCode,
    ]
  );

  return { updateCart, data, loading, error } as UpdateCartHook;
};

export { useUpdateCart };
