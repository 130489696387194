import { Text } from "@otrium/atoms";
import { Flex } from "@otrium/core";
import styled from "@emotion/styled";
import { ShoppingCart } from "src/icons/ShoppingCart";

const Wrapper = styled.div<{ withMobileOverlay: boolean }>`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.tone.white};
  padding: ${({ theme }) => theme.space[3]}px;
  max-height: 100%;
  overflow-y: auto;
  left: ${({ withMobileOverlay }) => (withMobileOverlay ? "unset" : 0)};
`;

const SizeGuideText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.tone.black};
`;

const SizeGuideButton = styled(SizeGuideText)`
  text-decoration: underline;
  font-weight: 700;
`;

const ShoppingCartIcon = styled(ShoppingCart)`
  path {
    stroke: ${({ theme }) => theme.colors.tone.white};
  }
`;

const OptionRow = styled(Flex)<{ selected: boolean }>`
  justify-content: space-between;
  align-items: center;
  height: 44px;
  margin-bottom: ${({ theme }) => theme.space[1]}px;
  margin-left: -${({ theme }) => theme.space[2]}px;
  margin-right: -${({ theme }) => theme.space[2]}px;
  ${({ selected, theme }) =>
    selected &&
    `
  background-color: ${theme.colors.tone.sand};
  border-radius: 8px;
  `}
`;

export { Wrapper, SizeGuideButton, ShoppingCartIcon, OptionRow, SizeGuideText };
