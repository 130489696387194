import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { SingleProduct } from "src/types/graphql.d";
import { formatName } from "src/utils/formatName";

interface ProductBySlugData {
  productBySlug: SingleProduct;
}

interface ProductBySlugVars {
  slug: string;
}

export const GET_PRODUCT = gql`
  query productBySlug($slug: String!) {
    productBySlug(slug: $slug) {
      id
      name
      status
      gender
      description
      material
      price
      main_category_ids
      categories
      regular_price
      lowest_price
      slug
      images
      size_schema
      is_currency_converted
      fit_size
      care_instructions
      promotion {
        promo_display_type
        promo_message
        promo_code
        promo_message_url
      }
      brand {
        id
        name
        slug
        good_on_you_info {
          score_meta
          certifications {
            id
            name
          }
          scores {
            animals
            people
            planet
            overall
          }
          pillars {
            animals
            planet
            people
            overall
          }
        }
      }
      variations {
        id
        size
        stock_quantity
      }
      shipping {
        shipping_text
      }
      payment_types {
        name
        image
      }
      labels {
        priority
        color
        text_color
        background_color
        value
        type
      }
      kids_sub_categories {
        id
        name
        slug
      }
      translated_category_names
      translated_category_ids
      product_discount
      is_personalised
      product_shop_type
      product_kids_gender
      sku
      currency {
        code
      }
    }
  }
`;

const useProductBySlug = (slug: string) => {
  const { loading, error, data } = useQuery<
    ProductBySlugData,
    ProductBySlugVars
  >(GET_PRODUCT, {
    variables: { slug },
    ssr: true,
    fetchPolicy: "cache-first",
  });

  const product: undefined | SingleProduct = data?.productBySlug && {
    ...data.productBySlug,
    name: formatName(data.productBySlug.name || ""),
    brand: {
      ...data.productBySlug.brand,
      name: formatName(data.productBySlug.brand?.name || ""),
    },
  };

  return { loading, error, product };
};

export { useProductBySlug };
