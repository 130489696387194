import { useMutation } from "@apollo/react-hooks";
import { ApolloError } from "@apollo/client";
import gql from "graphql-tag";
import { useContext } from "react";
import { ExecutionResult } from "graphql";
import { Session } from "next-auth";
import { useSession } from "next-auth/client";
import { useShippingMethodsByLocation } from "./useShippingMethodsByLocation";
import {
  Mutation,
  MyCartDraft,
  InventoryMode,
  ShippingMethod,
} from "src/types/ctgraphql.d";
import { AppCtx } from "src/contexts/app.context";

export interface CreateMyCartData {
  createMyCart: Mutation["createMyCart"];
}
interface CreateMyCartVars {
  cart: MyCartDraft;
  storeKey: string;
}

const CREATE_MY_CART = gql`
  mutation createMyCart($cart: MyCartDraft!, $storeKey: KeyReferenceInput) {
    createMyCart(draft: $cart, storeKey: $storeKey) {
      id
      version
      lineItems {
        id
        quantity
      }
    }
  }
`;

interface CreateCartHook {
  data?: CreateMyCartData | null;
  loading: boolean;
  error?: ApolloError;
  createCart: (cart: MyCartDraft) => Promise<ExecutionResult<CreateMyCartData>>;
}

let shippingMethodsByLocation: ShippingMethod[] | undefined;
let session: Session | null | undefined;

const useCreateCart = ({
  refetchQueries = [],
  onCompleted,
}: {
  refetchQueries?: string[];
  onCompleted?: (cartData: CreateMyCartData) => void;
} = {}): CreateCartHook => {
  const { storeKey, storeConfig, setCartInfo } = useContext(AppCtx);
  [session] = useSession();
  const [createCartMutation, { data, loading, error }] = useMutation<
    CreateMyCartData,
    CreateMyCartVars
  >(CREATE_MY_CART, {
    context: { useCT: true },
    refetchQueries,
    onCompleted,
  });

  const { data: shippingMethodsByLocationData } = useShippingMethodsByLocation({
    country: storeConfig.shippingAddressCountryCode,
  });
  shippingMethodsByLocation =
    shippingMethodsByLocationData?.shippingMethodsByLocation;

  const createCart = async (cart: MyCartDraft) => {
    const createdCart = await createCartMutation({
      variables: {
        cart: {
          country: storeConfig.countryCode,
          customerEmail: session?.user.email,
          shippingAddress: {
            country: storeConfig.shippingAddressCountryCode,
          },
          inventoryMode: InventoryMode.TrackOnly,
          shippingMethod: {
            id: shippingMethodsByLocation?.find(({ zoneRates }) =>
              zoneRates.find(({ shippingRates }) =>
                shippingRates.find(({ isMatching }) => isMatching)
              )
            )?.id,
          },
          lineItems: [],
          ...cart,
        },
        storeKey,
      },
    });

    if (createdCart.data?.createMyCart) {
      setCartInfo(
        createdCart.data?.createMyCart.id,
        createdCart.data?.createMyCart.version as number
      );
    }

    return createdCart;
  };

  return { createCart, data, loading, error };
};

export { useCreateCart };
