import { FC, SVGAttributes } from "react";

const Ruler: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 24 10" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M.75.75v8.5h22.5V.75H.75z" stroke="#000" strokeWidth="1.5" />
      <rect fill="#000" x="19" y="5" width="2" height="2" rx="1" />
      <path
        stroke="#000"
        strokeWidth="1.5"
        d="M4 1v3M7 1v5M10 1v3M16 1v3M13 1v5"
      />
    </g>
  </svg>
);

export { Ruler };
